<template>
  <div class="descr">
    <h3 class="seminars-info">
      <p>Если у вас не получается зайти пишите на Whatsapp:</p>
      (0555) 74-31-62 (Павел) / (0707) 49-92-22 (Темирбек)
    </h3>
    <div class="seminars-instruction">
      <a href="/doc/seminars_connect_web.docx">Инструкция для Windows</a>
      <a href="/doc/seminars_connect_mobile.mp4">Инструкция для Android, Iphone</a>
    </div>
    <p class="md-title ">
      <b>Этап:</b>
      {{ details.stage }}
    </p>
    <p class="md-title md-danger">
      <b>Дата:</b>
      {{ details.date }} - 9:30
    </p>
    <p class="md-title">
      <b>Участники</b>
      : {{ details.participants }}
    </p>
    <md-button :href="details.meetroom" class="md-primary enter-button">
      {{ $t('seminars.enter') }}
    </md-button>
  </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
  data() {
    return {
      stage: 0
    }
  },
  created() {
    this.stage = this.$route.params.stage
  },
  computed: {
    ...mapState({
      seminars: (state) => state.Seminars.list
    }),
    details() {
      return this.seminars.find((seminar) => seminar.stage === this.stage)
    }
  }
}
</script>

<style lang="scss" >
@import '@/assets/scss/material-dashboard.scss';
.descr {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.md-danger {
  font-size: 1.4rem;
  font-weight: 500;
  color: $brand-danger;
}
.enter-button {
  max-width: 200px;
}
.seminars-info {
  color: red;
  text-align: center;
  font-weight: 500;
}
.seminars-instruction {
  display: flex;
  justify-content: space-around;

  font-size: 1.5rem;
  padding: 10px 0;
  border-top: 1px solid;
  border-bottom: 1px solid;
  text-align: center;
}
</style>
